.recipe-overview {
  position: relative;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid white;
}

.recipe-overview-no-steps {
  width: 240px;
  padding: 16px;
  border: 1px solid #fff;
}

.recipe-overview-steps {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0 16px 16px 16px;
}

.recipe-overview-drop-target {
  position: absolute;
  width: 100%;
  height: 16px;
  top: 100%;
  z-index: 100;
  left: 0;
}

.recipe-overview-drop-target.first-target-drop-target {
  left: -16px;
}

.hovered-drop-target {
  background-color: blue;
}

.recipe-overview-controls {
  position: absolute;
  right: 0;
  bottom: 16px;
  padding: 8px;
  z-index: 100;
}