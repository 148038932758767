.step-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  overflow-y: auto;
}

.recipe-step-task {
  text-align: left;
}

.recipe-step-task-section {
  width: 100%;
  margin-top: 16px;
  border: 1px solid #333;
  padding: 16px 16px 0 16px;
}

.recipe-step-task label {
  margin-right: 16px;
}

.recipe-step textarea {
  width: 100%;
}