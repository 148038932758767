.header-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 0;
  z-index: 100;
  background-color: black;
  padding: 8px;
  width: 50vw;
  border: 1px solid #333;
}

.header-menu ul {
  list-style-type: none;
  padding: 0;
}

.header-menu-recipe {
  margin-bottom: 16px;
}
