.App-logo {
  height: 40px;
  pointer-events: none;
}

.App-header {
  position: relative;
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 8px;
  background-color: #282c34;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.title-container {
  display: flex;
  flex-direction: row;
}

.title-container h1 {
  margin: 0;
  margin-left: 16px;
}

.header-controls button {
  margin-left: 8px;
}