.recipe-step {
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recipe-step-task-section {
  margin-bottom: 16px;
  padding-top: 16px;
}