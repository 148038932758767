.recipe-overview-step-container {
  display: flex;
  padding-left: 24px;
}

.recipe-overview-step {
  position: relative;
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  height: auto;
  width: var(--recipe-step-width);
  min-height: var(--recipe-step-height);
  max-width: var(--recipe-step-width);
  min-width: var(--recipe-step-width);
  margin-top: 16px;
  border: 1px solid #333;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
}

.recipe-overview-step:first-of-type {
  margin-top: 16px;
}

.current-step .recipe-overview-step {
  z-index: 20;
  font-weight: bold;
  -webkit-box-shadow: 5px 5px 15px 5px var(--bland-mustard);
  box-shadow: 0px 0px 0px 5px var(--bland-mustard);
}

.current-step .recipe-step-arrow-backward,
.current-step .recipe-step-arrow-forward {
  font-weight: bold;
  background-color: var(--peoples-purple);
  border: 2px solid var(--bland-mustard);
}

.current-step .recipe-overview-step .recipe-step-arrow-same {
  font-weight: bold;
}

.current-step .recipe-overview-step .recipe-step-arrow-same::after {
  color: var(--peoples-purple);
}

.recipe-overview-step.human-task {
  background-color: var(--minty-teal);
  color: black;
}

.automated-task {
  background-color: var(--exciting-dark-blue);
}

.recipe-overview-step-index {
  position: absolute;
  top: 8px;
  left: 8px;
}

.recipe-overview-step-buttons {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 10;
}

.recipe-overview-step:hover .recipe-overview-step-buttons {
  display: block
}

.recipe-overview-step-buttons button {
  width: 32px;
  height: 32px;
  margin: 0;
  background-color: var(--cyberpunk-bubblegum);
}

.recipe-step-final-step {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.5rem;
}

.recipe-step-error-icon {
  font-size: 48px;
  color: var(--bland-mustard);
}

.recipe-overview-step-icon img {
  max-width: 60px;
  pointer-events: none;
}

.recipe-step-arrows {
  position: relative;
  left: 10px;
  z-index: 10;
  font-weight: normal;
  height: 24px;
  transform: rotate(90deg);
  transform-origin: bottom left;
}

.recipe-step-arrow-backward,
.recipe-step-arrow-forward {
  position: absolute;
  bottom: 0;
  height: fit-content;
  text-align: center;
  background-color: var(--peoples-purple);
  border: 1px solid #000;
  width: auto;
  padding: 4px;
}

.recipe-step-arrow-forward {
  padding: 0px 32px 0 4px;
}

.recipe-step-arrow-forward::after {
  content: '⇨';
  position: absolute;
  right: 0;
  font-size: 2em;
  line-height: 0.45;
}

.recipe-step-arrow-forward::before {
  content: '⇨';
  position: absolute;
  left: 0;
  font-size: 2em;
  line-height: 0.45;
}

.recipe-step-arrow-backward {
  padding: 0px 4px 0 32px;
}

.recipe-step-arrow-backward::after {
  content: '⇦';
  position: absolute;
  left: 0;
  font-size: 2em;
  line-height: 0.45;
}

.recipe-step-arrow-backward::before {
  content: '⇦';
  position: absolute;
  right: 0;
  font-size: 2em;
  line-height: 0.45;
}

.recipe-step-arrow-same {
  position: absolute;
  top: 100%;
  width: 80%;
  height: 50px;
  padding-top: 35px;
  /*  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 20px solid #ededed;
  border-top: 0;*/
}

.recipe-step-arrow-same::after {
  content: '↷';
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 21px;
  z-index: -1;
  transform: rotate(180deg);
  font-size: 8em;
  line-height: 0.4em;
  color: var(--peoples-purple);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

/* same-step semi-circle
.recipe-step-arrow-same::after {
  content: '';
  position: absolute;
  width: calc(100% + 42px);
  height: 56px;
  top: 0px;
  left: -21px;
  z-index: -1;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 22px solid #000;
  border-top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}*/