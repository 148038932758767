.parameters-list,
.options-list {
  margin-bottom: 0;
  padding: 0;
}

.parameters-list li,
.options-list li {
  list-style-type: none;
  padding: 8px 0;
  border-top: 1px solid #333;
}

.option-data-row,
.parameter-data-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.recipe-step-option label,
.recipe-step-parameter label {
  width: 100px;
  margin: 0 16px 0 0;
  text-align: left;
}

.recipe-step-option p {
  margin-top: 0;
}

.recipe-step-option input,
.recipe-step-parameter input {
  width: 100px;
  margin: 0 16px 0 0;
}

button.delete-option {
  background-color: var(--cyberpunk-bubblegum);
  min-width: 32px;
  height: 32px;
  margin: 0;
}